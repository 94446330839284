import React from "react"

import Layout from "../../components/Layout"
import PageTitle from "../../components/PageTitle"
import PracticeContent from "../../components/PracticeContent"

const MarkSouren = () => {
  return (
    <Layout title="Fysiotherapie Souren">
      <PageTitle title="Fysiotherapie Souren" />
      <PracticeContent name="Yvonne Heij">
        <small>BIG 29033808604</small>
        <p>In 1984 ben ik in Heerlen afgestudeerd als fysiotherapeute.</p>
        <p>
          Mijn specialisatie die in deze praktijk op de voorgrond staat is het
          diagnostiseren en behandelen van neurologische aandoeningen. (zoals na
          herseninfarct, bij aangeboren hersenaandoeningen, spasticiteit bij
          spierziekten).
        </p>
        <p>
          Soms ontstaan er “orthopedische” klachten zoals bv. een pijnlijke knie
          of schouder) ten gevolge van onderliggende neurologie. (bv. ten
          gevolge van de spasticiteit, een “verkeerd”looppatroon, een aangewende
          uitvoering van een beweging. N.A.P.therapie (neuro-orthopedische
          activiteitsafhankelijke plasticiteitstherapie naar Renata Horst) is
          hierbij een goede behandelmogelijkheid.
        </p>
        <ul>
          Verdere specialisaties zijn het behandelen en begeleiden bij:
          <li>COPD</li>
          <li>Claudicatio Intermittens (=etalagebenen, = PAV)</li>
          <li>COVID-19</li>
          <li>Hartfalen</li>
          <li>Facialisparese</li>
        </ul>
        <p>
          Ik ben aangesloten bij het Chronisch Zorgnet en ook terug te vinden op
          hun site en zorgzoeker. Het behandelen van claudicatiopatienten zie ik
          als een fijne uitdaging, die ik graag aanga samen met mijn patiënten.
        </p>
        <p>
          Sinds 2010 werk ik bij fysiotherapie Souren. Ik vind het een boeiend
          vak en ik werk graag met mensen. Ik heb een positieve gedreven
          instelling en zoek graag creatieve oplossingen. Gezelligheid en een
          gezonde dosis humor zijn voor mij tijdens de therapie gecombineerd met
          samen hard werken aan uw hulpvraag belangrijk.
        </p>
        <p>
          Ik lees veel en graag. Paardrijden, crossfit, yoga, wandelen en
          hardlopen vind ik fijn om te doen. Verder ben ik graag creatief bezig.
        </p>
      </PracticeContent>
      <PracticeContent name="Mark Souren">
        <small>BIG 59035071304</small>
        <p>
          In 1987 ben ik afgestudeerd aan de Academie voor Fysiotherapie te
          Heerlen.
        </p>
        <p>
          Aanvankelijk heb ik in Duitsland en in een verpleegkliniek in
          Maastricht gewerkt. Februari 1992 gestart met mijn eigen praktijk in
          Wijnandsrade.
        </p>
        <p>
          Door de jaren heen veel cursussen gevolgd . Dat niet alleen om mijn
          vak bij te houden maar ook om mijn kennis blijvend te verbreden en te
          verdiepen.
        </p>
        <p>
          Via Cyriax, Mc Kenzie en Mulligan als hoofdcursussen ben ik nu
          uitgekomen waar ik ben.
        </p>
        <p>
          Erg geïnteresseerd in sport gerelateerde problemen en oplossingen. De
          wervelkolom , knie , enkel en schouder behoren ook tot mijn extra
          aandachtsgebied. Ik hecht veel waarde aan zelfredzaamheid van de
          patient . Dus uitleg van het probleem met oefeningen en tips en
          adviezen zijn een belangrijk onderdeel van de behandeling.
        </p>
        <p>
          Als ik zelf het probleem niet kan oplossen, blijf ik zoeken naar een
          antwoord. Ik vind het niet erg om de oplossing te zoeken ook buiten
          mijn eigen praktijk. Het welzijn van mijn patient staat voorop.
        </p>
        <p>
          Zelf mountainbike ik graag en crossfit ik regelmatig. Dit om mijn
          Limburgse Bourgoundische levensstijl te kunnen blijven volhouden.
        </p>
      </PracticeContent>
    </Layout>
  )
}

export default MarkSouren
