import React from "react"
import Img, { FixedObject } from "gatsby-image"

type Props = {
  children: React.ReactNode
  image?: FixedObject
  name: string
}

const PracticeContent = ({ name, image, children }: Props) => {
  return (
    <div className="max-w-screen-md lg:max-w-screen-lg mx-auto my-12 px-12 py-16 bg-white rounded-md shadow-sm">
      <div className="flex flex-col sm:flex-row items-center sm:items-start">
        {image && <Img alt={name} fixed={image} className="rounded" />}

        <div
          className={`${
            image && "sm:ml-8 mt-12 sm:mt-0"
          } flex-1 prose lg:prose-lg`}
        >
          <h2 className="w-100 text-center sm:text-left">{name}</h2>
          {children}
        </div>
      </div>
    </div>
  )
}

export default PracticeContent
